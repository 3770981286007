import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../../_core/services/auth.service';
//import { PermissionNavigationService } from '../../services/permission-navigation.service';
import { Navigation } from '../../_core/interfaces/navigation';

@Injectable({
  providedIn: 'root'
})
export class LoggedInGuardService implements CanActivate {

  constructor(private authService: AuthService){
              //private permissionNavigationService: PermissionNavigationService) {
  }


  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let stateUrl = state.url.split('?')[0];

    if (!this.authService.isLoggedIn()) {
      this.authService.redirectToLogin();
      return false;
    } else {
      return true;
    }
  }

}
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { HeaderBasicAuthorizationService } from '../../../../_shared/services/header-basic-authorization.service';
import { ApiEnum } from '../../../../_shared/enums/api.enum';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FrontService {

  constructor(
    private http: HttpClient,
    private headerBasicAuthorization: HeaderBasicAuthorizationService
  ) { }

  public GetBanners() {
		return this.http.get(`${environment.api.WS_HCM.url}/Colaborador/GetBanners`,
		  { headers: this.headerBasicAuthorization.get(ApiEnum.WS_HCM) })
		 .pipe(map(data => data));
  }

  public GetTabs() {
	return this.http.get(`${environment.api.WS_HCM.url}/Colaborador/GetTabs`,
		{ headers: this.headerBasicAuthorization.get(ApiEnum.WS_HCM) })
		.pipe(map(data => data));
  }

  public GetTabsPorDNI(DNI) {
	return this.http.get(`${environment.api.WS_HCM.url}/Colaborador/GetTabsPorDNI?DNI=${DNI}`,
		{ headers: this.headerBasicAuthorization.get(ApiEnum.WS_HCM) })
		.pipe(map(data => data));
  }

  public ResetPasswordByUser(Usuario) {
	return this.http.get(`${environment.api.WS_HCM.url}/Colaborador/ResetPasswordByUser?prmstrUsuario=${Usuario}`,
		{ headers: this.headerBasicAuthorization.get(ApiEnum.WS_HCM) })
		.pipe(map(data => data));
  }

  public ValidateUserPreguntas(model) {
	return this.http.post(`${environment.api.WS_HCM.url}/Colaborador/ValidateUserPreguntas`, model,
		{ headers: this.headerBasicAuthorization.get(ApiEnum.WS_HCM) })
		.pipe(map(data => data));
  }

  public ResetPasswordByDatos(model) {
	return this.http.post(`${environment.api.WS_HCM.url}/Colaborador/ResetPasswordByDatos`, model,
		{ headers: this.headerBasicAuthorization.get(ApiEnum.WS_HCM) })
		.pipe(map(data => data));
  }

  public ValidateStatusLogin(Usuario) {
	return this.http.get(`${environment.api.WS_HCM.url}/Colaborador/ValidateStatusLogin?prmstrUsuario=${Usuario}`,
		{ headers: this.headerBasicAuthorization.get(ApiEnum.WS_HCM) })
		.pipe(map(data => data));
  }

  public GetBoletas(Usuario, Anio) {
	return this.http.get(`${environment.api.WS_HCM.url}/Colaborador/GetBoletas?prmstrUsuario=${Usuario}&prmstrAnio=${Anio}`,
		{ headers: this.headerBasicAuthorization.get(ApiEnum.WS_HCM) })
		.pipe(map(data => data));
  }

  public GetFile(fileName, accion, codigoBoleta) {
	return this.http.get(`${environment.api.WS_HCM.url}/Colaborador/GetFile?fileName=${fileName}&accion=${accion}&codigoBoleta=${codigoBoleta}`,
	  { headers: this.headerBasicAuthorization.get(ApiEnum.WS_HCM) })
		.pipe(map(data => data));
  }

  public ValidateDeclaracionJurada(DNI) {
	return this.http.get(`${environment.api.WS_HCM.url}/Colaborador/ValidateDeclaracionJurada?prmstrDNI=${DNI}`,
	  { headers: this.headerBasicAuthorization.get(ApiEnum.WS_HCM) })
		.pipe(map(data => data));
  }

  public GetAniosBoleta(Usuario) {
	return this.http.get(`${environment.api.WS_HCM.url}/Colaborador/GetAniosBoleta?prmstrUsuario=${Usuario}`,
		{ headers: this.headerBasicAuthorization.get(ApiEnum.WS_HCM) })
		.pipe(map(data => data));
  }

  public ObtenerBonoDiario(prmstrDNI) {
	return this.http.get(`${environment.api.WS_HCM.url}/Colaborador/ObtenerBonoDiario?prmstrDNI=${prmstrDNI}`,
		{ headers: this.headerBasicAuthorization.get(ApiEnum.WS_HCM) })
		.pipe(map(data => data));
  }

  public ObtenerUltimoDiaCerradoBono(prmstrDNI) {
	return this.http.get(`${environment.api.WS_HCM.url}/Colaborador/ObtenerUltimoDiaCerradoBono?prmstrDNI=${prmstrDNI}`,
		{ headers: this.headerBasicAuthorization.get(ApiEnum.WS_HCM) })
		.pipe(map(data => data));
  }

  public ListarAniosBonos(prmstrDNI) {
	return this.http.get(`${environment.api.WS_HCM.url}/Colaborador/ListarAniosBonos?prmstrDNI=${prmstrDNI}`,
		{ headers: this.headerBasicAuthorization.get(ApiEnum.WS_HCM) })
		.pipe(map(data => data));
  }

  public ListarTipoCosecha() {
	return this.http.get(`${environment.api.WS_HCM.url}/Colaborador/ListarTipoCosecha`,
		{ headers: this.headerBasicAuthorization.get(ApiEnum.WS_HCM) })
		.pipe(map(data => data));
  }

  public ObtenerBonoBisemanal(model) {
	return this.http.post(`${environment.api.WS_HCM.url}/Colaborador/ObtenerBonoBisemanal`, model,
		{ headers: this.headerBasicAuthorization.get(ApiEnum.WS_HCM) })
		.pipe(map(data => data));
  	}

	public ObtenerSemanas(prmintAnio, prmstrDNI) {
		return this.http.get(`${environment.api.WS_HCM.url}/Colaborador/ObtenerSemanas?prmintAnio=${prmintAnio}&prmstrDNI=${prmstrDNI}`,
		{ headers: this.headerBasicAuthorization.get(ApiEnum.WS_HCM) })
		.pipe(map(data => data));
	}

	public ListarGrupoEnvase() {
		return this.http.get(`${environment.api.WS_HCM.url}/Colaborador/ListarGrupoEnvase`,
			{ headers: this.headerBasicAuthorization.get(ApiEnum.WS_HCM) })
			.pipe(map(data => data));
	}

	public ListarTipoCosechaBono() {
		return this.http.get(`${environment.api.WS_HCM.url}/Colaborador/ListarTipoCosechaBono`,
			{ headers: this.headerBasicAuthorization.get(ApiEnum.WS_HCM) })
			.pipe(map(data => data));
	}

	public UpdatePasswordByDNI(DNI) {
		return this.http.get(`${environment.api.WS_IT.url}/Seguridad/UpdatePasswordByDNI?DNI=${DNI}`,
			{ headers: this.headerBasicAuthorization.get(ApiEnum.WS_IT) })
			.pipe(map(data => data));
	}
}
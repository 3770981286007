import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class CartService {
    private cart: BehaviorSubject<any> = new BehaviorSubject([]);

    getCart() {
        this.initializeCart();

        return this.cart.asObservable();
    }

    initializeCart() {
        var cart = {
            step: 1
        }
        this.cart.next(cart);
    }

    updateCart(cart) {
        this.cart.next(cart);
    }
}